<template>
    <div 
        v-if="portalEdit"
        class="flex flex-col content-start h-full">
        
        <div class="flex-none bg-beige flex flex-col h-32">
            <div class="flex-grow flex px-4 pt-4">
                <div 
                    class="flex-none w-12 h-12 flex items-center justify-center"
                    @click="$emit('close')">
                    
                    <i class="fas fa-times text-black text-xl" />
                </div>
                <div class="flex-grow flex items-center justify-center">
                    <h1>{{portalEdit.name}}</h1>
                </div>
                <div class="flex-none w-12 h-12 flex items-center justify-center">
                    
                </div>
            </div>
        </div>
        
        <div class="flex-grow overflow-auto px-8 py-2">
            <form @submit.prevent="onUpdateUser()">
                <InputSingleline 
                    v-model="portalEdit.name"
                    type="text"
                    label="Portalnavn"
                    :required="true"
                />
                
                <InputMultiline 
                    v-model="portalEdit.description"
                    :required="false"
                    label="Notater"
                    name="notes"
                />
                
                <label for="">Tilgangskode</label>
                <div 
                    class="text-6xl text-center bg-beige p-2 rounded border border-beige-dark" 
                    style="font-family: monospace;">
                    
                    {{portalEdit.accessCode}}
                </div>
                
            </form>
        </div>
        
        <div class="flex-none p-6 flex">
            <div class="flex-grow">
                <a 
                    class="button warning" 
                    @click.prevent="onDeletePortal()" >
                    
                    Slett
                </a>
            </div>
            <div class="flex-none space-x-4 text-right">
                <a 
                    class="button" 
                    @click="$emit('close')">
                    
                    Avbryt
                </a>
                
                <button 
                    type="submit"
                    @click.prevent="onUpdatePortal()" 
                    :disabled="loading == 'updating'"
                    class="button submit"
                    :class="{'loading' : loading == 'updating'}">
                    
                    Lagre
                </button>
            </div>
        </div>
    </div>
</template>



<script>
export default {
    props: {
        portal: {
            type: Object,
            required: true,
        }
    },
    
    computed:{
        
    },
    
    data() {
        return {
            loading: null,
            portalEdit: null,
        }
    },
    
    methods: {
        async onDeletePortal(){
            try {
                if (! confirm('Er du sikker på at du vil slette fellesbrukeren?')) return; 
                this.loading = 'deleting';
                const deletePortalResponse = await this.$store.dispatch('deletePortal', this.portalEdit);
                this.$notify({ type: 'success', text: 'Fellesbrukeren ble slettet' });
                this.$emit('close');
            } 
            catch (e) {
                this.$notify({ 
                    type: 'error', 
                    text: e.message || 'Kunne ikke slette fellesbrukeren'
                });
        
                console.error(e);
            } 
            finally {
                this.loading = null;
            }
        },
        
        async onUpdatePortal(){
            try {
                this.loading = 'updating';
                const updatePortalResponse = await this.$store.dispatch('updatePortal', this.portalEdit);
                await this.$store.dispatch('listPortals');
                this.$notify({ type: 'success', text: 'Endringen ble lagret' });
                this.$emit('close');
            } 
            catch (e) {
                this.$notify({ 
                    type: 'error', 
                    text: e.message || 'Kunne ikke lagre endringen'
                });
                
                console.error(e);
            } 
            finally {
                this.loading = null;
            }
        },
        
        createCopy(){
            this.portalEdit = JSON.parse(
                JSON.stringify({
                    ...this.portal,
                })
            );
        },
    },
    
    async mounted(){
        this.createCopy();
    },
}
</script>